import React from 'react';

class HorseInfo extends React.Component {
    render() {
        return (
            <div className="row">
                <div className="col">
                    <h2 className="subtitle">Overview</h2>
                    <p>Select a test in the menu...</p>
                </div>
            </div>
        )
    }
}

export default HorseInfo;