import React from 'react';

export const CompetitionInfo: React.FC = () => {
    return (
        <div className="row">
            <div className="col">
                <h2 className="subtitle">Overview</h2>
            </div>
        </div>
    )
}